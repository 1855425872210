import React from "react";
import { ClientConfiguration } from "../../models/ClientConfiguration/ClientConfiguration";
import { DriveMapping } from "../../models/ClientConfiguration/DriveMapping";
import {DocTypeMapping} from "../../models/ClientConfiguration/DocTypeMapping";
import {ImplementationType} from "../../models/OrganizationConfiguration/ImplementationType";
import DriveMappingsControls from "./DriveMappingsControl";
import DocTypeMappingsControl from "./DocTypeMappingsControl";

import { Grid } from "@mui/joy";

interface MappingsSettingsSectionProps {
  implementationType: ImplementationType;
  value: ClientConfiguration;
  onChange: (updatedConfig: ClientConfiguration) => void;
}

const MappingsSettingsSection: React.FC<MappingsSettingsSectionProps> = ({
  implementationType,
  value,
  onChange,
}) => {

  const handleDriveMappingsChange = (driveMappings: DriveMapping[]) => {
    onChange({
      ...value,
      driveMappings: driveMappings,
    });
  };

  const handleDocTypeMappingsChange = (mappings: DocTypeMapping[]) => {
    onChange({
      ...value,
      docTypeMappings: mappings,
    });
  };
  
  return (
    <Grid container height={"100%"} width={"100%"}>
      <Grid xs={12} minWidth={"100%"}>
        <DriveMappingsControls
          driveMappings={value.driveMappings || []}
          onChange={handleDriveMappingsChange}
        />
      </Grid>
      {implementationType === ImplementationType.TrialWorks ? (
        <Grid xs={12} >
          <DocTypeMappingsControl
            docTypeMappings={value.docTypeMappings || []}
            onChange={handleDocTypeMappingsChange}
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default MappingsSettingsSection;
