import React, { useEffect } from "react";
import { ClientConfiguration } from "../../models/ClientConfiguration/ClientConfiguration";
import { FirmDocumentFolder } from "../../models/ClientConfiguration/FirmDocumentFolder";
import {
  FlagType,
  FlaggingConfiguration,
} from "../../models/ClientConfiguration/FlaggingConfiguration";
import { RootCaseFolder } from "../../models/ClientConfiguration/RootCaseFolder";
import OutlinedDiv from "../common/OutlinedDiv";
import FileExtensionsFilterInput from "./FileExtensionsFilterInput";
import FileExtensionsFlagInput from "./FileExtensionsFlagInput";
import FirmDocumentsFoldersConfigurationControl from "./FirmDocumentsFoldersConfigurationControl";
import FlagTypeSelect from "./FlagTypeSelect";
import RootCaseFoldersConfigurationControl from "./RootCaseFoldersConfigurationControl";

import { FormControl, FormLabel, Grid, Input, Stack, Switch } from "@mui/joy";
import { ImplementationType } from "../../models/OrganizationConfiguration/ImplementationType";

interface MigrationConfigurationSectionProps {
  implementationType: ImplementationType;
  value: ClientConfiguration;
  onChange: (updatedConfig: ClientConfiguration) => void;
}

const MigrationConfigurationSection: React.FC<
  MigrationConfigurationSectionProps
> = ({ implementationType, value, onChange }) => {
  useEffect(() => {
    if (implementationType === ImplementationType.DocsToNeos) {
      value.discoverIntakes = false;
      value.discoverProviders = false;
      value.discoverTemplateFiles = false;
      value.migrateIntakes = false;
      value.migrateProviders = false;
      value.migrateTemplateFiles = false;
    }
  }, [implementationType, onChange]);

  const handleFlagTypesChange = (enabledFlags: FlaggingConfiguration[]) => {
    onChange({
      ...value,
      flags: enabledFlags,
    });
  };

  const handleFileExtensionFlagChange = (flaggedExtensions: string[]) => {
    const existingFlags =
      value.flags?.filter((f) => f.flagType !== FlagType.MsgCaseFile) || [];
    onChange({
      ...value,
      flags:
        flaggedExtensions.length > 0
          ? [
              ...existingFlags,
              {
                flagType: FlagType.MsgCaseFile,
                flagProperties: flaggedExtensions.map((extension) => {
                  return { key: "extension", value: extension };
                }),
              },
            ]
          : existingFlags,
    });
  };

  const handleFirmDocumentsFoldersChange = (folders: FirmDocumentFolder[]) => {
    onChange({
      ...value,
      firmDocumentFolders: folders,
    });
  };

  const handleRootCaseFoldersChange = (folders: RootCaseFolder[]) => {
    onChange({
      ...value,
      rootCaseFolders: folders,
    });
  };

  const handleMaxConcurrentUploadsChange = (maxUploads: string) => {
    const parsedMaxUploads = maxUploads ? parseInt(maxUploads) : null;
    onChange({
      ...value,
      maxConcurrentUploads:
        (parsedMaxUploads ?? 1) < 1
          ? 1
          : (parsedMaxUploads ?? 0) > 64
            ? 64
            : parsedMaxUploads,
    });
  };

  const handleMaxConcurrentDiscoveriesChange = (maxDiscoveries: string) => {
    const parsedMaxDiscoveries = maxDiscoveries
      ? parseInt(maxDiscoveries)
      : null;
    onChange({
      ...value,
      maxConcurrentDiscoveries:
        (parsedMaxDiscoveries ?? 1) < 1
          ? 1
          : (parsedMaxDiscoveries ?? 0) > 1024
            ? 1024
            : parsedMaxDiscoveries,
    });
  };

  const enabledFileExtensionFlags =
    value.flags
      ?.filter((f) => f.flagType === FlagType.MsgCaseFile)
      .at(0)
      ?.flagProperties.filter((prop) => prop.key === "extension")
      .map((prop) => prop.value) || [];

  return (
    <Grid container spacing={1} sx={{ flexGrow: 1 }}>
      <Grid xs={12} md={6} sx={{ flexGrow: 1 }}>
        <Grid container spacing={1} sx={{ flexGrow: 1 }}>
          <Grid xs={12} md={6} minWidth={"50%"}>
            <OutlinedDiv label="Enabled Discoveries">
              <Stack
                height={"100%"}
                spacing={1}
                sx={{ display: "flex", flexDirection: "column" }}
                justifyContent={"center"}
              >
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Cases</FormLabel>
                  <Switch
                    checked={value.discoverCases}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverCases: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={
                    implementationType != ImplementationType.TrialWorks &&
                    implementationType != ImplementationType.Api
                  }
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Intakes</FormLabel>
                  <Switch
                    checked={value.discoverIntakes}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverIntakes: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={
                    implementationType === ImplementationType.DocsToNeos ||
                    implementationType === ImplementationType.Api
                  }
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Providers</FormLabel>
                  <Switch
                    checked={value.discoverProviders}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverProviders: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={implementationType === ImplementationType.Api}
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Firm Documents</FormLabel>
                  <Switch
                    checked={value.discoverFirmDocuments}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverFirmDocuments: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={
                    implementationType === ImplementationType.DocsToNeos ||
                    implementationType === ImplementationType.Api
                  }
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Template Files</FormLabel>
                  <Switch
                    checked={value.discoverTemplateFiles}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        discoverTemplateFiles: event.target.checked,
                      })
                    }
                  />
                </FormControl>
              </Stack>
            </OutlinedDiv>
          </Grid>
          <Grid xs={12} md={6} minWidth={"50%"}>
            <OutlinedDiv label="Enabled Migrations">
              <Stack
                height={"100%"}
                spacing={1}
                sx={{ display: "flex", flexDirection: "column" }}
                justifyContent={"center"}
              >
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Cases</FormLabel>
                  <Switch
                    checked={value.migrateCases}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        migrateCases: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={
                    implementationType != ImplementationType.TrialWorks &&
                    implementationType != ImplementationType.Api
                  }
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Intakes</FormLabel>
                  <Switch
                    checked={value.migrateIntakes}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        migrateIntakes: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={
                    implementationType === ImplementationType.DocsToNeos ||
                    implementationType === ImplementationType.Api
                  }
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Providers</FormLabel>
                  <Switch
                    checked={value.migrateProviders}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        migrateProviders: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={implementationType === ImplementationType.Api}
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Firm Documents</FormLabel>
                  <Switch
                    checked={value.migrateFirmDocuments}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        migrateFirmDocuments: event.target.checked,
                      })
                    }
                  />
                </FormControl>
                <FormControl
                  disabled={
                    implementationType === ImplementationType.DocsToNeos ||
                    implementationType === ImplementationType.Api
                  }
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Template Files</FormLabel>
                  <Switch
                    checked={value.migrateTemplateFiles}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        migrateTemplateFiles: event.target.checked,
                      })
                    }
                  />
                </FormControl>
              </Stack>
            </OutlinedDiv>
          </Grid>

          <Grid xs={12} minWidth={"100%"}>
            <OutlinedDiv label="">
              <Stack
                height={"100%"}
                spacing={1}
                sx={{ display: "flex", flexDirection: "column", mt: 0.2 }}
                justifyContent={"center"}
              >
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>Upload Files Metadata To SharePoint</FormLabel>
                  <Switch
                    checked={value.uploadFilesMetadataToSharepoint}
                    onChange={(event) =>
                      onChange({
                        ...value,
                        uploadFilesMetadataToSharepoint: event.target.checked,
                      })
                    }
                  />
                </FormControl>
              </Stack>
            </OutlinedDiv>
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} md={6} minWidth={"50%"}>
        <Grid container spacing={1}>
          <Grid xs={12} md={6} minWidth={"100%"}>
            <FlagTypeSelect
              value={value.flags || []}
              onChange={handleFlagTypesChange}
            />
          </Grid>
          <Grid xs={12} md={6} minWidth={"100%"}>
            <FileExtensionsFlagInput
              extensionFlags={enabledFileExtensionFlags}
              onChange={handleFileExtensionFlagChange}
            />
          </Grid>
          <Grid xs={12} md={6} minWidth={"100%"}>
            <FileExtensionsFilterInput
              filters={value.fileTypeFilters || []}
              onChange={(newFilters) =>
                onChange({
                  ...value,
                  fileTypeFilters: newFilters,
                })
              }
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid xs={12} md={6}>
        <FormControl>
          <FormLabel>Maximum Number Of Concurrent File Uploads</FormLabel>
          <Input
            type="number"
            onChange={(event) =>
              handleMaxConcurrentUploadsChange(event.target.value)
            }
            value={
              value.maxConcurrentUploads === null
                ? ""
                : value.maxConcurrentUploads
            }
            fullWidth
          />
        </FormControl>
      </Grid>

      <Grid xs={12} md={6}>
        <FormControl>
          <FormLabel>Maximum Number Of Concurrent Discoveries</FormLabel>
          <Input
            type="number"
            onChange={(event) =>
              handleMaxConcurrentDiscoveriesChange(event.target.value)
            }
            value={
              value.maxConcurrentDiscoveries === null
                ? ""
                : value.maxConcurrentDiscoveries
            }
            fullWidth
          />
        </FormControl>
      </Grid>

      {implementationType !== ImplementationType.Api ? (
        <Grid xs={12} md={6}>
          <FirmDocumentsFoldersConfigurationControl
            folders={value.firmDocumentFolders || []}
            onChange={handleFirmDocumentsFoldersChange}
          />
        </Grid>
      ) : (
        <></>
      )}

      {implementationType !== ImplementationType.Api ? (
        <Grid xs={12} md={6}>
          <RootCaseFoldersConfigurationControl
            folders={value.rootCaseFolders || []}
            onChange={handleRootCaseFoldersChange}
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default MigrationConfigurationSection;
