import React from "react";
import OutlinedDiv from "../common/OutlinedDiv";
import {MigrationStrategy, MigrationStrategyLabels} from "../../models/OrganizationConfiguration/MigrationStrategy";

import { 
  Button, 
  ToggleButtonGroup 
} from "@mui/joy";

interface MigrationStrategySelectProps {
  value: MigrationStrategy | "";
  onChange: (value: MigrationStrategy | "") => void;
  label: string;
  required: boolean;
  disabled?: boolean;
}

const MigrationStrategySelect: React.FC<MigrationStrategySelectProps> = ({
  value,
  onChange,
  label,
  required = false,
  disabled
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedValue: MigrationStrategy | "",
  ) => {
    onChange(selectedValue);
  };

  return (
    <OutlinedDiv label={label}>
      <ToggleButtonGroup
        sx={{ width: "100%", height: "100%" }}
        value={value}
        onChange={(event, newValue) => {
          handleChange(event, newValue as MigrationStrategy);
        }}
        disabled={disabled}
      >
        {Object.values(MigrationStrategy).map((strategy) => (
          <Button fullWidth key={strategy} value={strategy}>
            {MigrationStrategyLabels[strategy]}
          </Button>
        ))}
      </ToggleButtonGroup>
    </OutlinedDiv>
  );
};

export default MigrationStrategySelect;
