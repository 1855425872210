import React from "react";
import { useQuery } from "react-query";
import ClientConfigurationForm from "../components/ClientConfiguration/ClientConfigurationForm";
import { ClientConfiguration } from "../models/ClientConfiguration/ClientConfiguration";
import { FlagType } from "../models/ClientConfiguration/FlaggingConfiguration";
import ClientConfigurationsRepository from "../repositories/ClientConfigurationsRepository";
import OrganizationsRepository from "../repositories/OrganizationsRepository";
import { ImplementationType } from "../models/OrganizationConfiguration/ImplementationType";
import { Box } from "@mui/joy";

interface ClientConfigurationPageProps {
  configurationId?: string;
  organizationId?: string;
}

const ClientConfigurationPage: React.FC<ClientConfigurationPageProps> = ({
  configurationId,
  organizationId,
}) => {
  const configurationsRepository = new ClientConfigurationsRepository();
  const organizationRepository = new OrganizationsRepository();
  let selectedConfig = undefined;

  const {
    data: configuration,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["clientConfigurations", configurationId],
    async () => {
      if (configurationId) {
        const config = await configurationsRepository.getClientConfiguration(
          configurationId ?? ""
        );
        return config;
      }
    },
    {
      enabled: !!configurationId,
      refetchOnMount: true,
      refetchInterval: 2 * 60 * 1000,
      refetchIntervalInBackground: false,
    }
  );

  const { data: implementationType } = useQuery(
    ["organizationImplementationType", organizationId],
    async () => {
      if (organizationId) {
        const implementationType =
          await organizationRepository.getOrganizationImplementationType(
            organizationId ?? ""
          );
        return implementationType;
      }
    },
    {
      refetchOnMount: true,
      refetchInterval: 2 * 60 * 1000,
      refetchIntervalInBackground: false,
    }
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {(error as Error).message}</div>;
  }

  selectedConfig =
    !!configurationId && configuration
      ? configuration
      : ({
          id: "",
          organizationId: organizationId ?? "",
          name: "",
          isDefault: true,
          dateCreated: "",
          migrateCases: true,
          migrateProviders: false,
          migrateTemplateFiles: false,
          migrateFirmDocuments: false,
          migrateIntakes: false,
          discoverCases: true,
          discoverProviders: false,
          discoverTemplateFiles: false,
          discoverFirmDocuments: false,
          discoverIntakes: false,
          uploadStarterTemplates: false,
          uploadFilesMetadataToSharepoint: true,
          sendDebugLogs: false,
          sharepointClientId: "ca64f31f-2781-418c-9f74-7eae4c9b4665",
          sharepointClientSecret: "NGY8Q~KnI4MvXfc8wUaUrypXtzx3-0SbKxLozbFO",
          flags: [
            { flagType: FlagType.CaseWithMoreThan10kFiles },
            { flagType: FlagType.DicomCaseFile },
            {
              flagType: FlagType.MsgCaseFile,
              flagProperties: [{ key: "extension", value: ".msg" }],
            },
          ],
          sharepointRootSiteName: "CloudDocs",
          casesSharepointRootDriveName: "CaseFiles",
          providersSharepointRootDriveName: "ProviderFiles",
          firmDocumentsSharepointRootDriveName: "FirmDocuments",
          templateFilesSharepointRootDriveName: "TemplateFiles",
          fileTypeFilters: [{ fileExtension: ".wpd" }],
          useCache: true,
          useDeltas: true,
          rebuildCache: false,
        } as ClientConfiguration);

  return (
    <Box minWidth="100%" height={"100%"} padding={0}>
      <ClientConfigurationForm
        implementationType={implementationType ?? ImplementationType.DocsToNeos}
        configuration={selectedConfig}
        key={implementationType}
      />
    </Box>
  );
};

export default ClientConfigurationPage;
