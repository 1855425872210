import React from "react";
import {
  CaseMigrationStrategy,
  CaseMigrationStrategyLabels
} from "../../models/OrganizationConfiguration/CaseMigrationStrategy";

import { 
  Button,
  Stack,
  ToggleButtonGroup
} from "@mui/joy";

interface CaseMigrationStrategySelectProps {
  value: CaseMigrationStrategy[];
  onChange: (value: CaseMigrationStrategy[]) => void;
  required: boolean;
  disabledCaseTypeSelection?: boolean;
  disabledDateTypeSelection?: boolean;
}

const CaseMigrationStrategySelect: React.FC<CaseMigrationStrategySelectProps> = ({ 
  value, 
  onChange, required,
  disabledCaseTypeSelection,
  disabledDateTypeSelection
}) => {
  const handleCaseToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedValue: CaseMigrationStrategy,
  ) => {
    const newValues: CaseMigrationStrategy[] = [
      ...value.filter(
        (val) =>
          ![
            CaseMigrationStrategy.AllCases,
            CaseMigrationStrategy.Custom,
          ].includes(val),
      ),
    ];
    newValues.push(selectedValue);
    onChange(newValues);
  };

  const handleDateToggleChange = (
    event: React.MouseEvent<HTMLElement>,
    selectedValue: CaseMigrationStrategy,
  ) => {
    const newValues: CaseMigrationStrategy[] = [
      ...value.filter(
        (val) =>
          ![
            CaseMigrationStrategy.DateAscending,
            CaseMigrationStrategy.DateDescending,
          ].includes(val),
      ),
    ];
    newValues.push(selectedValue);
    onChange(newValues);
  };

  return (
    <Stack spacing={1} sx={{ py: 1 }}>
      <ToggleButtonGroup
        sx={{ width: "100%", minHeight: "45px" }}
        value={
          value
            .filter((val) =>
              [
                CaseMigrationStrategy.AllCases,
                CaseMigrationStrategy.Custom,
              ].includes(val),
            )
            .at(0)
        }
        onChange={(event, newValue) => {
          handleCaseToggleChange(event, newValue as CaseMigrationStrategy);
        }}
      >
        <Button fullWidth value={CaseMigrationStrategy.AllCases} disabled={disabledCaseTypeSelection}>
          {CaseMigrationStrategyLabels[CaseMigrationStrategy.AllCases]}
        </Button>
        <Button fullWidth value={CaseMigrationStrategy.Custom} disabled={disabledCaseTypeSelection}>
          {CaseMigrationStrategyLabels[CaseMigrationStrategy.Custom]}
        </Button>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        sx={{ width: "100%", minHeight: "45px"  }}
        value={value
          .filter((val) =>
            [
              CaseMigrationStrategy.DateAscending,
              CaseMigrationStrategy.DateDescending,
            ].includes(val),
          )
          .at(0)}
        onChange={(event, newValue) => {
          handleDateToggleChange(event, newValue as CaseMigrationStrategy);
        }}
      >
        <Button fullWidth value={CaseMigrationStrategy.DateAscending} disabled={disabledDateTypeSelection}>
          {CaseMigrationStrategyLabels[CaseMigrationStrategy.DateAscending]}
        </Button>
        <Button fullWidth value={CaseMigrationStrategy.DateDescending} disabled={disabledDateTypeSelection}>
          {CaseMigrationStrategyLabels[CaseMigrationStrategy.DateDescending]}
        </Button>
      </ToggleButtonGroup>
    </Stack>
  );
};

export default CaseMigrationStrategySelect;
