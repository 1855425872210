import { Skeleton, Stack } from "@mui/joy";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import ListSubheader from "@mui/joy/ListSubheader";
import Sheet from "@mui/joy/Sheet";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { Organization } from "../../models/Organization";
import OrganizationsRepository from "../../repositories/OrganizationsRepository";
import ColorSchemeToggle from "./ColorSchemeToggle";
import LogoutButton from "./LogoutButton";
import { useOrganization } from "../../contexts/OrganizationContext";

interface SidebarProps {
  selectedItem?: string;
}

const Sidebar: React.FC<SidebarProps> = ({ selectedItem }) => {
  const navigate = useNavigate();
  const [orgData, setOrgData] = useState<{ [x: string]: Organization }>({});
  const { selectedOrganizationId } = useOrganization();
  const organizationsRepository = new OrganizationsRepository();

  const organizationQuery = useQuery(
    ["sidebar", selectedOrganizationId],
    async () => {
      const organizations =
        await organizationsRepository.getOrganizationsInfo();
      return organizations;
    },
    {
      staleTime: Infinity,
      enabled: !!selectedOrganizationId,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (organizationQuery.data) {
      const { data: organizations } = organizationQuery.data;
      if (organizations) {
        const dict = Object.fromEntries(organizations.map((x) => [x.id, x]));
        setOrgData(dict);
      }
    }
  }, [organizationQuery.data]);

  let organization;
  let loading = false;
  if (selectedOrganizationId) {
    const { isError, isFetching } = organizationQuery;

    loading = isFetching || isError;
    organization = orgData[selectedOrganizationId];

    if (!loading && !organization) {
      organizationQuery.refetch();
    }
  }

  const selectedOrgMenuItems = [
    {
      key: "Overview",
      label: "Overview",
      navigation: (orgId: string) => `/organization/${orgId}`,
    },

    {
      key: "Cases",
      label: "Cases",
      navigation: (orgId: string) => `/organization/${orgId}/cases`,
    },
    {
      key: "Organization Configuration",
      label: "Organization Configuration",
      navigation: (orgId: string) => `/organization/${orgId}/configuration`,
    },
    {
      key: "Client Configurations",
      label: "Client Configurations",
      navigation: (orgId: string) => `/organization/${orgId}/client-configurations`,
    },
    {
      key: "Clients",
      label: "Clients",
      navigation: (orgId: string) => `/organization/${orgId}/clients`,
    },

    // { key: "Providers", label: "Providers", navigation: (orgId: string) => "" },
    // {
    //   key: "Firm Documents",
    //   label: "Firm Documents",
    //   navigation: (orgId: string) => "",
    // },
    // {
    //   key: "Template Files",
    //   label: "Template Files",
    //   navigation: (orgId: string) => "",
    // },
  ];

  return (
    <React.Fragment>
      <Sheet
        className="Sidebar"
        color="neutral"
        sx={{
          position: {
            xs: "fixed",
            lg: "sticky",
          },
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))",
            lg: "none",
          },
          transition: "transform 0.4s",
          zIndex: 9999,
          height: "100dvh",
          top: 0,
          p: 2,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRight: "1px solid",
          borderColor: "divider",
          minWidth: "15vW",
          maxWidth: "15vW",
        }}
      >
        <List
          size="sm"
          sx={{ "--ListItem-radius": "6px", "--List-gap": "6px" }}
        >
          <ListSubheader role="presentation" sx={{ fontWeight: "lg" }}>
            Dashboard
          </ListSubheader>
          <ListItem key={1}>
            <ListItemButton
              selected={
                selectedOrganizationId === undefined &&
                selectedItem === "Organizations"
              }
              onClick={() => navigate("/organizations")}
            >
              <ListItemContent>Organizations</ListItemContent>
            </ListItemButton>
          </ListItem>
          <ListItem key={2}>
            <ListItemButton
              selected={selectedItem === "Users"}
              onClick={() => navigate("/users")}
            >
              <ListItemContent>Users</ListItemContent>
            </ListItemButton>
          </ListItem>
          {selectedOrganizationId && (
            <div>
              <Skeleton variant="inline" loading={loading}>
                <ListSubheader
                  role="presentation"
                  sx={{ fontWeight: 700, mt: 2, wordBreak: "break-word" }}
                >
                  {organization?.intactId} - {organization?.name}
                </ListSubheader>
              </Skeleton>
              <Skeleton variant="inline" loading={loading}>
                {selectedOrgMenuItems.map((menuItem, index) => (
                  <ListItem key={100 + index}>
                    <ListItemButton
                      selected={
                        selectedOrganizationId !== undefined &&
                        selectedItem === menuItem.key
                      }
                      onClick={() =>
                        navigate(menuItem.navigation(selectedOrganizationId))
                      }
                    >
                      <ListItemContent>{menuItem.label}</ListItemContent>
                    </ListItemButton>
                  </ListItem>
                ))}
              </Skeleton>
            </div>
          )}
        </List>
        <Stack direction={"row"} justifyContent={"space-between"}>
          <ColorSchemeToggle />
          <LogoutButton />
        </Stack>
      </Sheet>
    </React.Fragment>
  );
};

export default Sidebar;