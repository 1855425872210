import React from "react";
import { useQuery } from "react-query";
import OrganizationsRepository from "../repositories/OrganizationsRepository";
import {OrganizationConfiguration} from "../models/OrganizationConfiguration/OrganizationConfiguration";
import OrganizationConfigurationForm from "../components/OrganizationConfiguration/OrganizationConfigurationForm";
import {CaseMigrationStrategy} from "../models/OrganizationConfiguration/CaseMigrationStrategy";
import {MigrationStrategy} from "../models/OrganizationConfiguration/MigrationStrategy";
import { Box } from "@mui/joy";

interface OrganizationConfigurationPageProps {
  organizationId?: string;
}

const OrganizationConfigurationPage: React.FC<OrganizationConfigurationPageProps> = ({
  organizationId,
}) => {
  
  const organizationsRepository = new OrganizationsRepository();
  let selectedConfig = undefined;
  
  const {
    data: configuration,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["organizationConfiguration", organizationId],
    async () => {
      if (organizationId) {
        const config = await organizationsRepository.getOrganizationConfiguration(
          organizationId ?? "",
        );
        return config;
      }
    },
    {
      enabled: !!organizationId,
      refetchInterval: 2 * 60 * 1000,
      refetchIntervalInBackground: false,
    },
  );

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return <div>Error: {(error as Error).message}</div>;
  }

  selectedConfig =
    !!organizationId && configuration
      ? configuration
      : {
          migrateOpenedCases: false,
          migrateClosedCases: false,
          casesOpenedAfter: null,
          casesClosedAfter: null,
          casesMigrationStrategy: [
            CaseMigrationStrategy.AllCases,
            CaseMigrationStrategy.DateDescending,
          ],
          providersMigrationStrategy: MigrationStrategy.DateDescending,
          firmDocumentsMigrationStrategy: MigrationStrategy.DateDescending,
          templateFilesMigrationStrategy: MigrationStrategy.DateDescending,
          caseSpecifiedList: [],
          casePriorityList: [],
      } as OrganizationConfiguration;

  return (
    <Box sx={{
      width: {
        xs: '100vw',
        lg: 'calc(100vw - 15vw)',
      },
      height: '100vh',
      position: 'absolute',
      top: 0,
      left: {
        xs: 0,
        lg: '15vw',
      },
      py: 3,
      px: 6,
      overflowY: 'auto',
    }}>
      <OrganizationConfigurationForm
        organizationId={organizationId}
        configuration={selectedConfig}
      />
    </Box>
  );
};

export default OrganizationConfigurationPage;