import useApi from "../services/ApiService";
import { User } from "../models/User";
import {AxiosError, AxiosResponse } from "axios";

export class UsersRepository {
  private api = useApi();

  async getUsers(): Promise<{
    data: User[];
  }> {
    try {
      return await this.api.get("/api/Users", { params: { PageSize: 100 } });
    } catch (error) {
      throw new Error("Failed to fetch users");
    }
  }

  async getUserById(id: string): Promise<User> {
    try {
      const response = await this.api.get(`/api/Users/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(`Failed to fetch user with id: ${id}`);
    }
  }

  async createUser(
    username: string,
    email: string,
    password: string
  ): Promise<User | null> {
    try {
      const response: AxiosResponse<User> = await this.api.post("/api/Users/", {
        username: username,
        email: email,
        password: password,
      });
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response && error.response.status === 409) {
          alert(`User with username "${username}" already exists. Please choose a different username.`);
        }
      }
      return null;
    }
  }

  async updateUser(id: string, updatedUser: User): Promise<User> {
    try {
      const response: AxiosResponse<User> = await this.api.put(
        `/api/Users/${id}`,
        updatedUser
      );
      return response.data;
    } catch (error) {
      throw new Error(`Failed to update user with id: ${id}`);
    }
  }

  async updateUserToOrganizationAssignment(
    userId: string,
    organizationIds: string[]
  ): Promise<void> {
    try {
      return await this.api.put(
        `/api/Users/UpdateUserToOrganizationAssignment`,
        { organizationIds, userId }
      );
    } catch (error) {
      throw new Error(
        `Failed to update organization with ids: ${organizationIds} and user with id: ${userId}`
      );
    }
  }

  async deleteUser(userId: string): Promise<void> {
    try {
      return await this.api.delete(`/api/Users/${userId}`);
    } catch (error) {
      throw new Error("Failed to delete user");
    }
  }
}

export default UsersRepository;
