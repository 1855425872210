import React from "react";
import {useMutation, useQueryClient} from "react-query";
import {OrganizationConfiguration} from "../../models/OrganizationConfiguration/OrganizationConfiguration";
import OrganizationsRepository from "../../repositories/OrganizationsRepository";
import {ImplementationType} from "../../models/OrganizationConfiguration/ImplementationType";
import OrganizationFilesSynchronizationStrategySection from "./OrganizationFilesSynchronizationStrategySection";
import ImplementationTypeSelect from "../ClientConfiguration/ImplementationTypeSelect";

import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Typography,
  Button,
  Divider,
} from "@mui/joy";

interface OrganizationConfigurationFormProps {
  organizationId?: string;
  configuration: OrganizationConfiguration;
}

const OrganizationConfigurationForm: React.FC<OrganizationConfigurationFormProps> = ({
  organizationId,
  configuration,
}) => {

  const organizationsRepository = new OrganizationsRepository();
  const [config, setConfig] = React.useState(configuration);
  const [isUploading, setUploading] = React.useState(false);
  const queryClient = useQueryClient();

  const configMutation = useMutation(
    ["organizationConfiguration", organizationId],
    async (updatedConfig: OrganizationConfiguration) => {
      if (organizationId) {
        return await organizationsRepository.patchOrganizationConfiguration(
          organizationId || "",
          updatedConfig,
        );
      }
    }, {
      onSuccess: (data) => {
        queryClient.invalidateQueries("organizationConfiguration");
      },
      onSettled: () => {
        setUploading(false);
      },
    },
  );

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  const handleImplementationTypeChange = (
    implementationType: ImplementationType | undefined,
  ) => {
    setConfig({
      ...config,
      implementationType: implementationType
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', my: 1}}>
        <Skeleton variant="inline" loading={isUploading}>
          <Typography level="h2" textColor="text.primary" sx={{ pb: 1 }}>
            Organization configuration
          </Typography>
        </Skeleton>
        <Skeleton variant="inline" loading={isUploading}>
          <Button
            color="primary"
            onClick={() => {
              configMutation.mutate(config);
              setUploading(true);
            }}
            disabled={configMutation.isLoading}
          >
            Submit
          </Button>
        </Skeleton>
      </Box>

      <Skeleton variant="inline" loading={isUploading}>
        <Card variant={"outlined"} sx={{mb: 2}}>
          <CardContent>
            <Typography level="h4" component="div">
              Implementation Type
            </Typography>
            <Divider sx={{ mt:1, mb: 1}} />
            <ImplementationTypeSelect
              value={config.implementationType || undefined}
              onChange={handleImplementationTypeChange}
              required
            />
          </CardContent>
        </Card>
      </Skeleton>

      <Skeleton variant="inline" loading={isUploading}>
        <Card variant={"outlined"}>
          <CardContent>
            <Typography level="h4" component="div">
              Migration Strategy
            </Typography>
            <Divider sx={{ mt: 1, mb: 2 }} />
            <OrganizationFilesSynchronizationStrategySection
              value={config}
              onChange={(updatedConfig) => setConfig(updatedConfig)}
            />
          </CardContent>
        </Card>
      </Skeleton>

    </form>
  );
};

export default OrganizationConfigurationForm;
