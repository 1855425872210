/* eslint-disable jsx-a11y/anchor-is-valid */
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Chip from "@mui/joy/Chip";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Sheet from "@mui/joy/Sheet";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { IconButton, Skeleton, Stack } from "@mui/joy";
import Fuse from "fuse.js";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getFormattedDate } from "../../helpers";
import { Order, compareBy, stableSort } from "../../helpers/TableHelpers";
import { Organization, OrganizationWithPartialStatistics } from "../../models/Organization";
import OrganizationsRepository from "../../repositories/OrganizationsRepository";
import { ClearableSelect } from "../common/ClearableSelect";
import SortableTableColumnHeader from "../common/SortableTableColumnHeader";
import UsersRepository from "../../repositories/UsersRepository";
import {User} from "../../models/User";
import GenericAutocomplete from "../common/GenericAutocomplete";

// icons
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Delete } from "@mui/icons-material";

const SecretViewer: React.FC<{ organization: Organization }> = ({
  organization,
}) => {
  const [isSecretVisible, setIsSecretVisible] = useState(false);

  const toggleSecretVisibility = () => {
    setIsSecretVisible((prevState) => !prevState);
  };

  return (
    <>
      {isSecretVisible ? (
        <Stack direction="row" padding={0} justifyContent={"start"}>
          <Button
            sx={{ padding: "0px" }}
            onClick={toggleSecretVisibility}
            variant="plain"
            color="neutral"
            startDecorator={<VisibilityOffIcon sx={{ padding: "0px" }} />}
          />
          <Typography sx={{ padding: 1, wordBreak: "break-word" }} level="body-sm">
            {organization.organizationSecret}
          </Typography>
        </Stack>
      ) : (
        <Button
          onClick={toggleSecretVisibility}
          variant="plain"
          color="neutral"
          sx={{ padding: "0px" }}
          startDecorator={<VisibilityIcon sx={{ padding: "0px" }} />}
        >
          {isSecretVisible ? "Hide Secret" : "Show Secret"}
        </Button>
      )}
    </>
  );
};

interface OrganizationsTableProps {
  onDeleteOrganization: (configId: string) => void;
}

const OrganizationsTable: React.FC<OrganizationsTableProps> = ({
  onDeleteOrganization
}) => {
  const [order, setOrder] =
    React.useState<Order>("desc");
  const [sort, setSort] =
    React.useState<string>("dateCreated");
  const [processFilter, setRunningProcessFilter] =
    React.useState<string | null>(null);
  const [stepFilter, setStepFilter] =
    React.useState<string | null>(null);
  const [assignedUserFilter, setAssignedUserFilter] =
    React.useState<string | null>(null);
  const [searchQuery, setSearchQuery] =
    useState("");
  const [organizations, setOrganizations] =
    useState<OrganizationWithPartialStatistics[]>([]);
  const [users, setUsers] =
    useState<User[]>([]);

  const navigate = useNavigate();
  const organizationsRepository = new OrganizationsRepository();
  const usersRepository = new UsersRepository();

  const organizationsQuery = useQuery(
    "organizations",
    async () => {
      return await organizationsRepository.getOrganizations();
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: true,
    },
  );

  const userQuery = useQuery(
    "users",
    async () => {
      return await usersRepository.getUsers();
    },
    {
      staleTime: 5 * 60 * 1000,
      refetchInterval: 5 * 60 * 1000 + 1,
      refetchIntervalInBackground: true,
      refetchOnMount: false,
    },
  );

  useEffect(() => {
    if (organizationsQuery.data?.data) {
      setOrganizations(organizationsQuery.data?.data);
    }
    if (userQuery.data) {
      setUsers(userQuery.data.data);
    }
  }, [organizationsQuery, userQuery.data]);

  const userOptions = users.map(user => ({
    id: user.id,
    label: user.username,
  }));
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSort('');
    setSearchQuery(event.target.value);
  };
  
  const options = {
    keys: ["name", "intactId"],
    includeScore: true,
    threshold: 0.5,
    caseSensitive: false,
  };
  const { isError, isFetching } = organizationsQuery;
  let filteredOrganizations = [] as OrganizationWithPartialStatistics[];
  const fuse = new Fuse(organizations, options);
  const searchResults = fuse.search(searchQuery);

  filteredOrganizations = (
    searchQuery ? searchResults.map((result) => result.item) : organizations
  ).filter((org) => {
    if (processFilter || stepFilter || assignedUserFilter) {
      const latestStep = org.latestStep;
      let satisfiesProcessAndStepFilter = true;
      
      if (processFilter) {
        satisfiesProcessAndStepFilter = satisfiesProcessAndStepFilter && ((latestStep?.type ?? "none") === processFilter);
      }
      
      if (stepFilter) {
        satisfiesProcessAndStepFilter = satisfiesProcessAndStepFilter && ((latestStep?.step ?? "none") === stepFilter);
      }
      
      let satisfiesAssignedUserFilter = true;
      if (assignedUserFilter && assignedUserFilter !== "none") {
        satisfiesAssignedUserFilter = org.assignedUser && (org.assignedUser.id === assignedUserFilter);
      }

      return satisfiesProcessAndStepFilter && satisfiesAssignedUserFilter;
    }
    return true;
  });
  
  const sortedResults = sort
    ? stableSort<OrganizationWithPartialStatistics>(
      filteredOrganizations,
      compareBy(order, sort),
    )
    : filteredOrganizations;
  
  const renderFilters = () => (
    <React.Fragment>
      <Skeleton variant="inline" loading={isFetching || isError}>
        <FormControl size="sm">
          <FormLabel>Running Process</FormLabel>
          <ClearableSelect
            onChange={(newValue) => setRunningProcessFilter(newValue)}
            size="sm"
            placeholder="Filter by process"
            slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          >
            <Option value="migration" color="success">
              Migration
            </Option>
            <Option value="statisticsDiscovery" color="primary">
              Discovery
            </Option>
            <Option value="none">None</Option>
          </ClearableSelect>
        </FormControl>
      </Skeleton>
      
      <Skeleton variant="inline" loading={isFetching || isError}>
        <FormControl size="sm">
          <FormLabel>Step</FormLabel>
          <ClearableSelect
            size="sm"
            placeholder="Filter by step"
            onChange={(newValue) => setStepFilter(newValue)}
          >
            <Option value="started" color="success">
              Started
            </Option>
            <Option value="interrupted" color="danger">
              Interrupted
            </Option>
            <Option value="finished" color="primary">
              Finished
            </Option>
            <Option value="none">None</Option>
          </ClearableSelect>
        </FormControl>
      </Skeleton>

      <Skeleton variant="inline" loading={isFetching || isError}>
        <FormControl size="sm" sx={{ width: 300 }}>
          <FormLabel>Assigned to</FormLabel>
          <ClearableSelect
            size="sm"
            placeholder="Filter by assigned user"
            onChange={(newValue) => setAssignedUserFilter(newValue)}
          >
            {userOptions.map((option) => (
              <Option key={option.id} value={option.id}>
                {option.label}
              </Option>
            ))}
          </ClearableSelect>
        </FormControl>
      </Skeleton>
    </React.Fragment>
  );
  return (
    <React.Fragment>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>Search for organization</FormLabel>
            <Input
              size="sm"
              placeholder="Search"
              startDecorator={<SearchIcon />}
              onChange={handleSearchChange}
            />
          </FormControl>
        </Skeleton>
        {renderFilters()}
      </Box>
      <Sheet
        className="OrganizationsTableContainer"
        variant="outlined"
        sx={{
          display: { xs: "initial" },
          width: "100%",
          borderRadius: "sm",
          flexShrink: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Skeleton variant="inline" loading={isFetching || isError}>
          <Table
            aria-labelledby="tableTitle"
            stickyHeader
            hoverRow
            sx={{
              "--TableCell-headBackground":
                "var(--joy-palette-background-level1)",
              "--Table-headerUnderlineThickness": "1px",
              "--TableRow-hoverBackground":
                "var(--joy-palette-background-level1)",
              "--TableCell-paddingY": "4px",
              "--TableCell-paddingX": "8px",
            }}
          >
            <thead>
            <tr>
              <th
                style={{
                  width: "2%",
                  padding: "12px 6px",
                }}
              />
              <th
                style={{
                  width: "10%",
                  padding: "12px 6px",
                }}
              >
                <SortableTableColumnHeader
                  label={"IntactID"}
                  sortProperty={"intactId"}
                  getSort={() => sort}
                  setSort={(newSort) => setSort(newSort)}
                  getOrder={() => order}
                  setOrder={(newOrder) => setOrder(newOrder)}
                />
              </th>
              <th
                style={{width: "20%", padding: "12px 6px"}}
              >
                <SortableTableColumnHeader
                  label={"Name"}
                  sortProperty={"name"}
                  getSort={() => sort}
                  setSort={(newSort) => setSort(newSort)}
                  getOrder={() => order}
                  setOrder={(newOrder) => setOrder(newOrder)}
                />
              </th>
              <th
                style={{width: "10%", padding: "12px 6px"}}
              >
                Progress
              </th>
              <th
                style={{width: "25%", padding: "12px 6px"}}
              >
                Secret
              </th>
              <th
                style={{width: "12%", padding: "12px 6px"}}
              >
                <SortableTableColumnHeader
                  label={"Date Created"}
                  sortProperty={"dateCreated"}
                  getSort={() => sort}
                  setSort={(newSort) => setSort(newSort)}
                  getOrder={() => order}
                  setOrder={(newOrder) => setOrder(newOrder)}
                />
              </th>
              <th
                style={{
                  width: "20%",
                  padding: "12px 6px"
                }}
              >
                Assigned to
              </th>
              <th
                style={{
                  width: "5%",
                  minWidth: 140,
                  padding: "12px 6px"
                }}
              />
            </tr>
            </thead>
            <tbody>
            {sortedResults.map((organization) => {
              const currentStep = organization.latestStep;
              const migratedPercentage = organization.hasDiscoveredFiles ? (organization.migratedFilesSizeInGb / organization.totalFilesSizeInGb) * 100 : 0;
              let assignedUser = {id: organization.assignedUser.id, label: organization.assignedUser.username}

              const handleSelectUser = async (selectedOption: { id: string; label: string }, organizationId: string) => {
                assignedUser.id = selectedOption.id;
                assignedUser.label = selectedOption.label;

                try {
                  await organizationsRepository.patchOrganizationAssignedUser(organizationId, selectedOption.id);
                } catch (error) {
                  console.error(error);
                }
              };
              
              return (
                <tr
                  key={organization.id}
                  onDoubleClick={() =>
                    navigate(`/organization/${organization.id}`)
                  }
                >
                  <td style={{textAlign: "center", width: 60}}></td>
                  <td>
                    <Typography level="body-sm" sx={{wordBreak: "break-all"}}>
                      {organization.intactId}
                    </Typography>
                  </td>
                  <td>
                    <Typography level="body-md" sx={{wordBreak: "break-word"}}>
                      {organization.name}
                    </Typography>
                  </td>
                  <td>
                    {organization.hasDiscoveredFiles ? (
                      <Chip
                        sx={{textAlign: "center"}}
                        variant="soft"
                        size="sm"
                        color={
                          migratedPercentage > 99.99
                            ? "success"
                            : "primary"
                        }
                      >
                        {(((migratedPercentage % 1) > 0.99) || ((migratedPercentage % 1) < 0.01))
                          ? `${migratedPercentage.toFixed(
                            0,
                          )}%`
                          : `${migratedPercentage.toFixed(
                            2,
                          )}%`}
                      </Chip>
                    ) : (
                      <Chip variant="soft" size="sm" color="danger">
                        No files
                      </Chip>
                    )}
                  </td>
                  <td>
                    <SecretViewer organization={organization}/>
                  </td>
                  <td>
                    <Typography level="body-sm">
                      {getFormattedDate(organization.dateCreated as string)}
                    </Typography>
                  </td>
                  <td>
                    <GenericAutocomplete
                      options={userOptions}
                      inputValue={assignedUser}
                      setValue={(selectedOption) => handleSelectUser(selectedOption, organization.id)}
                      placeholder="Assigned user"
                      size="sm"
                    />
                  </td>
                  <td>
                    <IconButton
                      aria-label="Delete"
                      size="sm"
                      color="danger"
                      onClick={() => onDeleteOrganization(organization.id ?? "")}
                    >
                      <Delete/>
                    </IconButton>
                  </td>
                </tr>
              );
            })}
            </tbody>
          </Table>
        </Skeleton>
      </Sheet>
    </React.Fragment>
  );
};

export default OrganizationsTable;
