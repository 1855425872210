import React, { useState, useEffect } from "react";
import {SourceApiType} from "../../models/SourceApi/SourceApiType";
import {ClientConfiguration} from "../../models/ClientConfiguration/ClientConfiguration";
import {SourceApiConfigurationJson} from "../../models/SourceApi/SourceApiConfigurationJson";
import {deserializeJson, serializeJson} from "../../helpers/JsonSerializer";

import {
  Box, 
  Input, 
  Select, 
  Option, 
  Grid,
  FormControl,
  FormLabel,
  IconButton,
  Switch,
} from "@mui/joy";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import OutlinedDiv from "../common/OutlinedDiv";

interface FieldInfo {
  label: string;
  type: 'text' | 'boolean';
}

interface ApiSectionProps {
  value: ClientConfiguration;
  onChange: (updatedConfig: ClientConfiguration) => void;
}

const initialTypeSpecificDataMap: Record<SourceApiType, { config: SourceApiConfigurationJson; baseUrl: string }> = {
  [SourceApiType.Filevine]: {
    config: {
      apiKey: '',
      apiSecret: '',
      useBatchEndpoint: false,
    },
    baseUrl: 'https://{company-name}.api.filevineapp.com',
  },

  // Mocked OAuth fields
  // [SourceApiType.OAuth]: {
  //   config: {
  //     clientId: '',
  //     clientSecret: '',
  //     redirectUri: '',
  //   },
  //   baseUrl: 'https://oauth.example.com',
  // },
};

const fieldLabelsPlaceholders: Record<string, FieldInfo> = {
  apiKey: { label: "API Key", type: 'text' },
  apiSecret: { label: "API Secret", type: 'text' },
  useBatchEndpoint: { label: "Use Batch Document Download Endpoint", type: 'boolean' },
  
  //Mocked OAuth fields
  // clientId: { label: "Client ID", type: 'text' },
  // clientSecret: { label: "Client Secret", type: 'text' },
  // redirectUri: { label: "Redirect URI", type: 'text' },
};

const ApiSection: React.FC<ApiSectionProps> = ({
  value,
  onChange,
}) => {
  const [typeSpecificData, setTypeSpecificData] = useState<SourceApiConfigurationJson | null>(null);

  useEffect(() => {
    try {
      const deserializedAuthData = deserializeJson<SourceApiConfigurationJson>(value.sourceApiConfigurationJson);
      setTypeSpecificData(deserializedAuthData);
    } catch (e) {
      console.error("Failed to deserialize sourceApiConfigurationJson:", e);
      setTypeSpecificData(null);
    }
  }, [value.sourceApiConfigurationJson, value.sourceApiType]);
  
  const handleTypeChange = (newType: SourceApiType) => {
    const initialData = initialTypeSpecificDataMap[newType]?.config || {};
    const initialBaseUrl = initialTypeSpecificDataMap[newType]?.baseUrl || '';
    onChange({
      ...value,
      sourceApiType: newType,
      sourceApiConfigurationJson: serializeJson(initialData),
      sourceApiBaseUrl: initialBaseUrl,
    });
    setTypeSpecificData(initialData as SourceApiConfigurationJson);
  };

  const handleBaseUrlChange = (newBaseUrl: string) => {
    onChange({
      ...value,
      sourceApiBaseUrl: newBaseUrl,
    });
  };

  const handleMaxRequestsPerSecondChange = (maxRequestsPerSecond: string) => {
    if (maxRequestsPerSecond.trim() === "") {
      onChange({
        ...value,
        sourceApiMaxRequestsPerSecond: null
      });
      return;
    }

    const parsedMaxUploads = parseInt(maxRequestsPerSecond);
    if (!isNaN(parsedMaxUploads) && parsedMaxUploads > 0) {
      onChange({
        ...value,
        sourceApiMaxRequestsPerSecond: parsedMaxUploads
      });
    }
  };

  const handleMaxRequestsPerHourChange = (maxRequestsPerHour: string) => {
    if (maxRequestsPerHour.trim() === "") {
      onChange({
        ...value,
        sourceApiMaxRequestsPerHour: null
      });
      return;
    }

    const parsedMaxUploads = parseInt(maxRequestsPerHour);
    if (!isNaN(parsedMaxUploads) && parsedMaxUploads > 0) {
      onChange({
        ...value,
        sourceApiMaxRequestsPerHour: parsedMaxUploads
      });
    }
  };

  const handleMaxRequestsPerDayChange = (maxRequestsPerDay: string) => {
    if (maxRequestsPerDay.trim() === "") {
      onChange({
        ...value,
        sourceApiMaxRequestsPerDay: null
      });
      return;
    }

    const parsedMaxUploads = parseInt(maxRequestsPerDay);
    if (!isNaN(parsedMaxUploads) && parsedMaxUploads > 0) {
      onChange({
        ...value,
        sourceApiMaxRequestsPerDay: parsedMaxUploads
      });
    }
  };

  const [showSecretValue, setShowSecretValue] = useState<Record<string, boolean>>({
    apiSecret: false,
    apiKey: false,
    clientSecret: false,
  });

  const handleToggleSecretVisibility = (field: string) => {
    setShowSecretValue((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleTypeSpecificFieldChange = (field: keyof SourceApiConfigurationJson, fieldValue: string | boolean) => {
    if (typeSpecificData) {
      const updatedTypeSpecificData = { ...typeSpecificData, [field]: fieldValue };
      setTypeSpecificData(updatedTypeSpecificData);
      onChange({
        ...value,
        sourceApiConfigurationJson: serializeJson(updatedTypeSpecificData),
      });
    }
  };

  const renderInputFields = () => {
    if (!typeSpecificData) return null;

    return Object.keys(typeSpecificData).map((field) => {
      const { label, type } = fieldLabelsPlaceholders[field] || { label: field, type: 'text' };
      const isSecretField = field.toLowerCase().includes("secret") || field.toLowerCase().includes("key");

      return (
        <Box key={field} sx={{ mb: 0.5 }}>
          {type === 'boolean' ? (
            <Box sx={{ mt: 1 }}>
              <OutlinedDiv label="">
                <FormControl
                  orientation="horizontal"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                >
                  <FormLabel>{label}</FormLabel>
                  <Switch
                    checked={typeSpecificData[field as keyof SourceApiConfigurationJson] as boolean}
                    onChange={(e) => handleTypeSpecificFieldChange(field as keyof SourceApiConfigurationJson, e.target.checked)}
                  />
                </FormControl>
              </OutlinedDiv>
            </Box>
          ) : (
            <FormControl>
              <FormLabel>{label}</FormLabel>
              <Input
                type={isSecretField && !showSecretValue[field] ? 'password' : 'text'}
                value={typeSpecificData[field as keyof SourceApiConfigurationJson] as string}
                onChange={(e) => handleTypeSpecificFieldChange(field as keyof SourceApiConfigurationJson, e.target.value)}
                fullWidth
                endDecorator={
                  isSecretField && (
                    <IconButton onClick={() => handleToggleSecretVisibility(field)}>
                      {showSecretValue[field] ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  )
                }
              />
            </FormControl>
          )}
        </Box>
      );
    });
  };

  return (
    <Box>
      <Grid container spacing={1} sx={{ mb: 0.5 }}>
        <Grid xs={4} minWidth={"33%"}>
          <FormControl>
            <FormLabel>Type</FormLabel>
            <Select
              value={value.sourceApiType}
              onChange={(e, newType) => handleTypeChange(newType as SourceApiType)}
              slotProps={{ root: { sx: { width: '100%' } } }}
            >
              <Option value={SourceApiType.Filevine}>Filevine</Option>
              {/*<Option value={SourceApiType.OAuth}>OAuth</Option>*/}
            </Select>
          </FormControl>
        </Grid>

        <Grid xs={8} minWidth="66%">
          <FormControl>
            <FormLabel>Base URL</FormLabel>
            <Input
              value={value.sourceApiBaseUrl}
              onChange={(e) => handleBaseUrlChange(e.target.value)}
            />
          </FormControl>
        </Grid>

        <Grid xs={4} minWidth="33%">
          <FormControl>
            <FormLabel>Request limit per second</FormLabel>
            <Input
              type="number"
              onChange={(event) => handleMaxRequestsPerSecondChange(event.target.value)}
              value={value.sourceApiMaxRequestsPerSecond === null ? "" : value.sourceApiMaxRequestsPerSecond}
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid xs={4} minWidth="33%">
          <FormControl>
            <FormLabel>Request limit per hour</FormLabel>
            <Input
              type="number"
              onChange={(event) => handleMaxRequestsPerHourChange(event.target.value)}
              value={value.sourceApiMaxRequestsPerHour === null ? "" : value.sourceApiMaxRequestsPerHour}
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid xs={4} minWidth="33%">
          <FormControl>
            <FormLabel>Request limit per day</FormLabel>
            <Input
              type="number"
              onChange={(event) => handleMaxRequestsPerDayChange(event.target.value)}
              value={value.sourceApiMaxRequestsPerDay === null ? "" : value.sourceApiMaxRequestsPerDay}
              fullWidth
            />
          </FormControl>
        </Grid>

        
      </Grid>
      
      {/*Filevine inputs*/}
      {value.sourceApiType === SourceApiType.Filevine && (
        <Box>
          {renderInputFields()}
        </Box>
      )}

      {/*Mocked OAuth inputs*/}
      {/*{value.sourceApiType === SourceApiType.OAuth && (*/}
      {/*  <Box>*/}
      {/*    {renderInputFields()}*/}
      {/*  </Box>*/}
      {/*)}*/}
      
    </Box>
  );
};

export default ApiSection;
